<template>
  <div>
    <orientation v-if="isLandscape"></orientation>
    <div v-else>
      <div v-if="isValidVoucherType">
        <coupon v-if="voucherType > 0 && voucherType < 5"></coupon>
        <poker v-if="voucherType === 5"></poker>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Coupon from './components/Coupon.vue'
import Poker from './components/Poker.vue'

export default {
  components: { Coupon, Poker },
  computed: {
    ...mapState(['isLandscape', 'voucherType']),
    voucherImg() {
      return require(`@/assets/images/voucher/coupon/voucher-${this.voucherType}.png`)
    },
    isValidVoucherType() {
      if (this.voucherType > 0 && this.voucherType < 6) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
