<template>
  <transition name="fade">
    <div v-if="show" class="two-outer">
      <div style="flex-grow: 8"></div>
      <img src="@/assets/images/home/title-two.png" :class="titleClasses" />
      <div style="flex-grow: 1"></div>
      <img
        :src="buttonSrc"
        class="button"
        @click="buttonClick"
        @mousedown="buttonActive"
        @touchstart="buttonActive"
        @mouseup="buttonInactive"
        @touchend="buttonInactive"
      />
      <div style="flex-grow: 1"></div>
      <img src="@/assets/images/home/footer-two.png" class="footer" />
      <home-decoration></home-decoration>
    </div>
  </transition>
</template>
<script>
import HomeDecoration from '@/components/HomeDecoration'
export default {
  components: { HomeDecoration },
  data() {
    return {
      show: false,
      titleClasses: ['title'],
      buttonSrc: require('@/assets/images/home/button-two.png')
    }
  },

  mounted() {
    setTimeout(() => {
      // duration: 2s
      this.show = true
    }, 1)
  },
  methods: {
    buttonClick() {
      this.show = false
      setTimeout(() => {
        this.$emit('end')
      }, 2500)
    },
    buttonActive() {
      this.buttonSrc = require('@/assets/images/home/button-two-pressed.png')
    },
    buttonInactive() {
      this.buttonSrc = require('@/assets/images/home/button-two.png')
    }
  }
}
</script>
<style lang="scss" scoped>
.two-outer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.title {
  width: 120px;
}
.button {
  cursor: pointer;
  width: 200px;
  max-height: 158px;
}

.footer {
  width: 280px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
