<template>
  <div class="voucher-poker-outer" :style="styles">
    <img
      src="@/assets/images/voucher/poker/header.png"
      class="mt-1"
      style="width: 360px"
    />
    <div class="w-370 flex flex-row justify-start">
      <img
        src="@/assets/images/voucher/poker/logo.png"
        class="ml-2"
        style="width: 193px"
      />
    </div>
    <div class="relative">
      <img
        src="@/assets/images/voucher/poker/voucher-5.png"
        class="-mt-4"
        style="width: 327px"
      />
      <span
        class="absolute"
        style="right: 60px; bottom: 20px; color: #bbbbba; font-size: 18px"
      >
        {{ seqNo }}
      </span>
    </div>
    <div class="flex flex-row justify-start items-center -mt-12 w-370">
      <div style="flex-grow: 0.4"></div>
      <img class="two" src="@/assets/images/voucher/poker/decoration/two.png" />
      <div style="flex-grow: 0.4"></div>
      <img class="one" src="@/assets/images/voucher/poker/decoration/one.png" />
      <div style="flex-grow: 0.2"></div>
    </div>
    <div class="flex flex-col -mt-6 items-center justify-start">
      <img
        src="@/assets/images/voucher/poker/footer-msg.png"
        style="width: 189px"
      />
      <img
        src="@/assets/images/voucher/poker/footer-instruction.png"
        style="width: 363px"
      />
      <img
        src="@/assets/images/voucher/poker/footer.png"
        style="width: 360px"
        class="mt-2"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['screenAvailHeight', 'seqNo']),
    styles() {
      return { height: this.screenAvailHeight + 'px' }
    }
  }
}
</script>
<style lang="scss" scoped>
.voucher-poker-outer {
  width: 100vw;
  // height: 100vh;
  background-image: url('~@/assets/images/voucher/poker/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}
.one {
  width: 160px;
  // position: fixed;
  // bottom: 76vh;
  // right: 0vw;
}
.two {
  width: 80px;
  // position: fixed;
  // bottom: 83vh;
  // left: 63vw;
}
</style>
