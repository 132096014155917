const mixin = {
  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.$store.commit('SET_WINDOW_WIDTH', { windowWidth: window.innerWidth })
      this.$store.commit('SET_WINDOW_HEIGHT', { windowHeight: window.innerHeight })
      this.$store.commit('SET_SCREEN_WIDTH', { screenWidth: window.screen.width })
      this.$store.commit('SET_SCREEN_HEIGHT', { screenHeight: window.screen.height })
      this.$store.commit('SET_SCREEN_AVAIL_WIDTH', { screenAvailWidth: window.screen.availWidth })
      this.$store.commit('SET_SCREEN_AVAIL_HEIGHT', { screenAvailHeight: window.screen.availHeight })

      let isLandscape = false
      if (window.innerWidth > window.innerHeight) isLandscape = true
      this.$store.commit('SET_IS_LANDSCAPE', { isLandscape })
    },
    onOrientationChange() {
      this.windowOrientation = window.screen.orientation.type
      let isLandscape = false
      if (this.windowOrientation === 'landscape-primary') isLandscape = true
      this.$store.commit('SET_IS_LANDSCAPE', { isLandscape })
    }
  }
}
export default mixin
