import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isValidUser: false,
    isLandscape: false,
    voucherType: null,
    shakeType: null,
    seqNo: null,
    windowWidth: null,
    windowHeight: null,
    screenWidth: null,
    screenHeight: null,
    screenAvailWidth: null,
    screenAvailHeight: null
  },
  mutations: {
    SET_IS_VALID_USER(state, { isValidUser }) {
      state.isValidUser = isValidUser
    },
    SET_SEQ_NO(state, { seqNo }) {
      state.seqNo = seqNo
    },
    SET_SCREEN_WIDTH(state, { screenWidth }) {
      state.screenWidth = screenWidth
    },
    SET_SCREEN_HEIGHT(state, { screenHeight }) {
      state.screenHeight = screenHeight
    },
    SET_SCREEN_AVAIL_WIDTH(state, { screenAvailWidth }) {
      state.screenAvailWidth = screenAvailWidth
    },
    SET_SCREEN_AVAIL_HEIGHT(state, { screenAvailHeight }) {
      state.screenAvailHeight = screenAvailHeight
    },
    SET_WINDOW_WIDTH(state, { windowWidth }) {
      state.windowWidth = windowWidth
    },
    SET_WINDOW_HEIGHT(state, { windowHeight }) {
      state.windowHeight = windowHeight
    },
    SET_IS_LANDSCAPE(state, { isLandscape }) {
      state.isLandscape = isLandscape
    },
    SET_VOUCHER_TYPE(state, { voucherType }) {
      state.voucherType = voucherType
    },
    SET_SHAKE_TYPE(state, { shakeType }) {
      state.shakeType = shakeType
    }
  },
  actions: {
    reset({ commit }) {
      commit('SET_SHAKE_TYPE', { shakeType: null })
      commit('SET_VOUCHER_TYPE', { voucherType: null })
      commit('SET_SEQ_NO', { seqNo: null })
    },
    win({ commit }, { seqNo }) {
      commit('SET_SEQ_NO', { seqNo })
      const seqType = seqNo.substring(0, 1)

      let voucherType
      if (seqType === 'A') {
        voucherType = 1
      } else if (seqType === 'B') {
        voucherType = 2
      } else if (seqType === 'C') {
        voucherType = 3
      } else if (seqType === 'D') {
        voucherType = 4
      } else if (seqType === 'E') {
        voucherType = 5
      }
      commit('SET_VOUCHER_TYPE', { voucherType })
    }
  },
  modules: {
  }
})
