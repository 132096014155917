import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import windowSize from '@/mixins/windowSize'
import Orientation from '@/components/Orientation'

Vue.config.productionTip = false

Vue.component('Orientation', Orientation)
new Vue({
  router,
  store,
  mixins: [windowSize],
  render: h => h(App)
}).$mount('#app')
