<template>
  <div>
    <orientation v-if="isLandscape"></orientation>
    <div
      v-else-if="isValidShakeType"
      class="shake-outer flex flex-row"
      :style="{
        height: screenAvailHeight + 'px',
        backgroundImage: `url(${require(`@/assets/images/shake/background-${shakeType}.png`)})`
      }"
    >
      <div
        class="
          bg-white
          rounded-full
          flex flex-col
          items-center
          opacity-70
          -mt-8
        "
        style="width: 186px; height: 186px"
      >
        <img
          src="@/assets/images/shake/shake.gif"
          class="mt-4"
          style="width: 113px"
        />
        <img
          src="@/assets/images/shake/msg.png"
          style="width: 101px"
          class="-mt-2"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        axios.get(
          `${process.env.VUE_APP_API_SERVER_URL}/api/count?type=participants`
        )
        this.$router.push({ name: 'Recipe', params: { isValid: true } })
      }, 7000)
    })
  },
  computed: {
    ...mapState(['isLandscape', 'screenAvailHeight', 'shakeType']),
    isValidShakeType() {
      if (this.shakeType > 0 && this.shakeType < 6) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.shake-outer {
  width: 100vw;
  // height: 100vh;
  // background-image: url('~@/assets/images/shake/background-3-1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
