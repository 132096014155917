<template>
  <div class="h-full w-full flex flex-col">
    <transition name="fade">
      <div v-if="show" class="relative">
        <img
          class="w-full"
          src="@/assets/images/question/header-background.png"
        />
        <img
          class="absolute w-full"
          style="top: 0"
          src="@/assets/images/question/header-title.png"
        />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="show"
        class="flex flex-row justify-start items-start mx-auto mt-4 w-330"
      >
        <img src="@/assets/images/question/q1.png" class="-ml-3" style="width: 48px" />
        <img src="@/assets/images/question/q1-msg.png" style="width: 287px" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="show" class="relative w-330 mx-auto flex-grow -mt-2">
        <img
          src="@/assets/images/question/icon-1-1.png"
          class="one"
          @click="buttonClick"
        />
        <img
          src="@/assets/images/question/icon-1-2.png"
          class="two"
          @click="buttonClick"
        />
        <img
          src="@/assets/images/question/icon-1-3.png"
          class="three"
          @click="buttonClick"
        />
        <img
          src="@/assets/images/question/icon-1-4.png"
          class="four"
          @click="buttonClick"
        />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    setTimeout(() => {
      // duration: 2s
      this.show = true
    }, 1)
  },
  methods: {
    buttonClick() {
      this.show = false
      setTimeout(() => {
        this.$emit('end')
      }, 2000)
    }
  }
}
</script>
<style lang="scss" scoped>
.one {
  position: absolute;
  width: 264px;
  top: -5px;
  left: 30px;
}
.two {
  position: absolute;
  width: 240px;
  top: 230px;
  left: -10px;
}
.three {
  position: absolute;
  width: 160px;
  top: 180px;
  right: -20px;
}
.four {
  position: absolute;
  width: 200px;
  top: 360px;
  left: -10px;
}
.fade-enter-active {
  transition: opacity 2s;
}
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
