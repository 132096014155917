<template>
  <div>
    <orientation v-if="isLandscape"></orientation>
    <div v-else class="recipe-outer" :style="{ height: `${height}` }">
      <one v-if="shakeType === 1"></one>
      <two v-if="shakeType === 2"></two>
      <three v-if="shakeType === 3"></three>
      <four v-if="shakeType === 4"></four>
      <five v-if="shakeType === 5"></five>
      <div v-if="seqNo != null" class="fixed" style="bottom: 0px">
        <img
          style="width: 351px"
          src="@/assets/images/recipe/winner.png"
          @click="$router.push({ name: 'Voucher', params: { isValid: true } })"
        />
      </div>
    </div>
  </div>
</template>
<script>
import One from './components/One'
import Two from './components/Two'
import Three from './components/Three'
import Four from './components/Four'
import Five from './components/Five'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: { One, Two, Three, Four, Five },
  async created() {
    try {
      if (this.shakeType != null && !localStorage.getItem('win')) {
        const response = await axios.get(
          `${process.env.VUE_APP_API_SERVER_URL}/api/occupy`
        )
        axios.get(
          `${process.env.VUE_APP_API_SERVER_URL}/api/count?type=winners`
        )
        this.$store.dispatch('win', { seqNo: response.data.seqno })
        localStorage.setItem('win', true)
      }
    } catch (err) {
      console.error(err)
    }
  },
  computed: {
    ...mapState([
      'isLandscape',
      'windowWidth',
      'screenAvailHeight',
      'shakeType',
      'seqNo'
    ]),
    height() {
      if (this.seqNo == null) {
        return '1720px'
      }
      return '1790px'
    }
  }
}
</script>
<style lang="scss" scoped>
.recipe-outer {
  width: 100vw;
  // height: 100vh;
  background-image: url('~@/assets/images/recipe/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
