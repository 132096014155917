import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
    meta: { isPass: true }
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import(/* webpackChunkName: "question" */ '@/views/Question'),
    props: true
  },
  {
    path: '/shake',
    name: 'Shake',
    component: () => import(/* webpackChunkName: "question" */ '@/views/Shake'),
    props: true
  },
  {
    path: '/recipe',
    name: 'Recipe',
    component: () => import(/* webpackChunkName: "question" */ '@/views/Recipe'),
    props: true
  },
  {
    path: '/voucher',
    name: 'Voucher',
    component: () => import(/* webpackChunkName: "question" */ '@/views/Voucher'),
    props: true
  },
  {
    path: '/screen',
    name: 'Screen',
    component: () => import(/* webpackChunkName: "screen" */ '@/views/Screen.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isPass || to.params.isValid) {
    next()
  } else {
    next({ name: 'Home' })
  }
})

export default router
