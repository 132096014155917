<template>
  <div>
    <img src="@/assets/images/home/title-one.png" :class="titleClasses" />
    <transition name="fade">
      <home-decoration v-if="show"></home-decoration>
    </transition>
  </div>
</template>
<script>
import HomeDecoration from '@/components/HomeDecoration'
export default {
  components: { HomeDecoration },
  data() {
    return {
      show: false,
      titleClasses: ['title']
    }
  },
  mounted() {
    setTimeout(() => {
      // duration: 2s
      this.show = true
    }, 1)
    setTimeout(() => {
      // duration: 2s
      this.show = false
      this.titleClasses.push('fade-out')
    }, 2000)
    setTimeout(() => {
      this.titleClasses.push('hidden')
      this.$emit('end')
    }, 4000)
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 180px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
