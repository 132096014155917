<template>
  <div class="voucher-coupon-outer" :style="styles">
    <img src="@/assets/images/voucher/coupon/logo.png" style="width: 155px" />
    <div class="relative">
      <img class="-mt-2" :src="voucherImg" style="width: 349px" />
      <span
        class="absolute"
        style="right: 50px; bottom: 10px; color: #bbbbba; font-size: 18px"
      >
        {{ seqNo }}
      </span>
    </div>
    <div class="flex flex-row -mt-4 items-center">
      <img
        src="@/assets/images/voucher/coupon/activity.png"
        class="-mr-2"
        style="width: 124px"
      />
      <img
        src="@/assets/images/voucher/coupon/info.png"
        class="-ml-2 mt-3"
        style="height: 108px"
      />
    </div>
    <div class="voucher-coupon-footer flex flex-col items-center">
      <img
        class="mt-2"
        src="@/assets/images/voucher/coupon/footer-msg.png"
        style="width: 254px"
      />
      <img :src="footerInstructionImg" class="-mt-2" style="width: 370px" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['isLandscape', 'voucherType', 'screenAvailHeight', 'seqNo']),
    styles() {
      return { height: this.screenAvailHeight + 'px' }
    },
    voucherImg() {
      return require(`@/assets/images/voucher/coupon/voucher-${this.voucherType}.png`)
    },
    footerInstructionImg() {
      return require(`@/assets/images/voucher/coupon/footer-instruction-${this.voucherType}.png`)
    }
  }
}
</script>
<style lang="scss" scoped>
.voucher-coupon-outer {
  width: 100vw;
  // height: 100vh;
  background-image: url('~@/assets/images/voucher/coupon/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.voucher-coupon-footer {
  width: 100vw;
  height: 146px;
  background-image: url('~@/assets/images/voucher/coupon/background-footer.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 10;
  bottom: 0;
  position: absolute;
}
</style>
