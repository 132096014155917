<template>
  <div class="h-full w-full flex flex-col">
    <transition name="fade">
      <div v-if="show" class="relative">
        <img
          class="w-full"
          src="@/assets/images/question/header-background.png"
        />
        <img
          class="absolute w-full"
          style="top: 0"
          src="@/assets/images/question/header-title.png"
        />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="show"
        class="flex flex-row justify-start items-start mx-auto mt-2 w-370"
      >
        <img src="@/assets/images/question/q3.png" style="width: 48px" />
        <img src="@/assets/images/question/q3-msg.png" style="width: 274px" />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="show"
        class="w-370 mx-auto flex flex-col items-center justify-between mt-1"
        style="height: 520px"
      >
        <img
          src="@/assets/images/question/icon-3-1.png"
          class="img"
          @click="buttonClick(1)"
        />
        <img
          src="@/assets/images/question/icon-3-2.png"
          class="img"
          @click="buttonClick(2)"
        />
        <img
          src="@/assets/images/question/icon-3-3.png"
          class="img"
          @click="buttonClick(3)"
        />
        <img
          src="@/assets/images/question/icon-3-4.png"
          class="img"
          @click="buttonClick(4)"
        />
        <img
          src="@/assets/images/question/icon-3-5.png"
          class="img"
          @click="buttonClick(5)"
        />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    setTimeout(() => {
      // duration: 2s
      this.show = true
    }, 1)
  },
  methods: {
    buttonClick(shakeType) {
      this.$store.commit('SET_SHAKE_TYPE', { shakeType })
      this.show = false
      setTimeout(() => {
        this.$emit('end')
      }, 2000)
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 2s;
}
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.img {
  width: 264px;
}
</style>
