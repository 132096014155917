<template>
  <div>
    <orientation v-if="isLandscape"></orientation>
    <div v-else class="question-outer" :style="styles">
      <one v-if="showOne" @end="endOne"></one>
      <two v-if="showTwo" @end="endTwo"></two>
      <three v-if="showThree" @end="endThree"></three>
    </div>
  </div>
</template>
<script>
import One from './components/One'
import Two from './components/Two'
import Three from './components/Three'
import { mapState } from 'vuex'
export default {
  components: { One, Two, Three },
  data() {
    return {
      showOne: true,
      showTwo: false,
      showThree: false
    }
  },
  methods: {
    endOne() {
      this.showOne = false
      this.showTwo = true
    },
    endTwo() {
      this.showTwo = false
      this.showThree = true
    },
    endThree() {
      console.log('endThree..')
      this.$router.push({ name: 'Shake', params: { isValid: true } })
    }
  },
  computed: {
    ...mapState(['isLandscape', 'screenAvailHeight']),
    styles() {
      return { height: this.screenAvailHeight + 'px' }
    }
  }
}
</script>
<style lang="scss" scoped>
.question-outer {
  width: 100vw;
  // height: 100vh;
  background-image: url('~@/assets/images/question/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
