<template>
  <div class="debug-border-red w-screen h-screen">
    <p>isLandscape: {{ isLandscape }}</p>
    <p>windowWidth: {{ windowWidth }}</p>
    <p>windowHeight: {{ windowHeight }}</p>
    <p>screenAvailWidth: {{ screenAvailWidth }}</p>
    <p>screenAvailHeight: {{ screenAvailHeight }}</p>
    <p>screenWidth: {{ screenWidth }}</p>
    <p>screenHeight: {{ screenHeight }}</p>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'isLandscape',
      'windowWidth',
      'windowHeight',
      'screenWidth',
      'screenHeight',
      'screenAvailWidth',
      'screenAvailHeight'
    ])
  }
}
</script>
