<template>
  <div>
    <orientation v-if="isLandscape"></orientation>
    <div v-else class="home-outer">
      <one v-if="showOne" @end="endOne"></one>
      <two v-if="showTwo" @end="endTwo"></two>
      <three v-if="showThree" @end="endThree"></three>
    </div>
  </div>
</template>
<script>
import One from './components/One'
import Two from './components/Two'
import Three from './components/Three'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: { One, Two, Three },
  data() {
    return {
      showOne: true,
      showTwo: false,
      showThree: false
    }
  },
  created() {
    this.$store.dispatch('reset')
    this.$store.commit('SET_IS_VALID_USER', { isValidUser: true })
    axios.get(`${process.env.VUE_APP_API_SERVER_URL}/api/count?type=users`)
  },
  methods: {
    endOne() {
      this.showOne = false
      this.showTwo = true
    },
    endTwo() {
      this.showTwo = false
      this.showThree = true
    },
    endThree() {
      this.$router.push({ name: 'Question', params: { isValid: true } })
    }
  },
  computed: {
    ...mapState(['isLandscape'])
  }
}
</script>
<style lang="scss" scoped>
.home-outer {
  width: 100vw;
  height: 100vh;
  background-image: url('~@/assets/images/home/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
